.mat-standard-chip {
    padding: px2vw-xl(7px) px2vw-xl(12px) !important;
    border-radius: 0 !important;
    min-height: px2vw-xl(32px) !important;

    @include lg {
        padding: px2vw-lg(7px) px2vw-lg(12px) !important;
        border-radius: 0 !important;
        min-height: px2vw-lg(32px) !important;
    }

    @include md {
        padding: px2vw-md(7px) px2vw-md(12px) !important;
        border-radius: 0 !important;
        min-height: px2vw-md(32px) !important;
    }

    @include sm {
        padding: px2vw-sm(7px) px2vw-sm(12px) !important;
        border-radius: 0 !important;
        min-height: px2vw-sm(32px) !important;
    }
}

/* You can add global styles to this file, and also import other style files */

// variables
@import "./theme/colors";
@import "./theme/variables";
@import "./theme/functions";
@import "./theme/mixins";

// override third party styles
@import "theme/override/material/index";
@import "theme/override/ngx-pagination/index";
@import "theme/override/ks-carousel/index";
@import "theme/override/drag-scroll/index";
//@import "theme/override/nouislider/index";

// app theme
@import "./theme/typography";
@import "./theme/font";
@import "./theme/utils";
@import "./theme/button";
@import "./theme/input";
@import "./theme/global";
@import "./theme/titles";
@import "./theme/link";
@import "./theme/popup";
@import "./theme/table";
@import "./theme/wrappers";
@import "./theme/skeleton";
@import "./theme/loading";

@import "./theme/components/search";
@import "./theme/components/volvoLazyImage";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@media print {
    .isPrinting > * { display: none; }
    .isPrinting volvo-print-layout { display: block; }
  }

:root {
    --tablet-side-padding: 32px;
    --mobile-side-padding: 16px;
    --tablet-hero-margin: -32px;
    --mobile-hero-margin: -16px;
}

/* stylelint-disable */
.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.tal {
    text-align: left;
}

.tdn {
    text-decoration: none;
}

.wwbw {
    word-wrap: break-word;
}

.wsnw {
    white-space: nowrap;
    color: var(--cta-blue);
}

.w100p {
    width: 100%;
}

.fw700 {
    font-weight: 700;
}

.hrShadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 1) 100%);
    content: "";
    height: px2vw-xl(5px);

    @include lg {
        height: px2vw-lg(5px);
    }

    @include md {
        height: px2vw-md(5px);
    }

    @include sm {
        height: px2vw-sm(5px);
    }
}

/* stylelint-enable */

.Link {
    color: var(--ocean);
    cursor: pointer;
    font-size: px2vw-xl(16px);
    font-weight: 700;

    @include lg {
        font-size: px2vw-lg(16px);
    }

    @include md {
        font-size: px2vw-md(16px);
    }

    @include sm {
        font-size: px2vw-sm(16px);
    }
}

.Table-cell .Link {
    @include sm {
        display: block;
    }
}

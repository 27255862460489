.Popup {
  background: var(--alabaster2);

  &-wp {
    padding: px2vw-xl(20px);

    @include lg {
      padding: px2vw-lg(20px);
    }

    @include md {
      padding: px2vw-md(20px);
    }

    @include sm {
      padding: px2vw-sm(20px);
    }
  }

  &-WithFooter {
    padding-bottom: px2vw-xl(40px);

    @include lg {
      padding-bottom: px2vw-lg(40px);
    }

    @include md {
      margin: 0 px2vw-md(25px);
      padding-bottom: px2vw-md(40px);
    }

    @include sm {
      margin: 0 px2vw-sm(15px);
      padding-bottom: px2vw-sm(40px);
    }
  }

  &-header {
    align-items: center;
    background-color: #f6f6f6; //var(--fog);
    box-shadow: 0 px2vw-xl(1px) px2vw-xl(3px) 0 rgba(0, 0, 0, 0.16);
    display: flex;
    font-size: px2vw-xl(16px);
    justify-content: center;
    min-height: px2vw-xl(40px);
    position: relative;

    @include lg {
      box-shadow: 0 px2vw-lg(1px) px2vw-lg(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-lg(16px);
      min-height: px2vw-lg(40px);
    }

    @include md {
      box-shadow: 0 px2vw-md(1px) px2vw-md(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-md(16px);
      min-height: px2vw-md(40px);
      padding: px2vw-md(30px) 0 px2vw-md(30px) px2vw-md(25px);
      background: #FFFFFF;
      justify-content: left;
      line-height: px2vw-md(21px);
    }

    @include sm {
      //box-shadow: 0 px2vw-sm(1px) px2vw-sm(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-sm(16px);
      min-height: px2vw-sm(40px);
      padding: px2vw-sm(30px) 0 px2vw-sm(30px) px2vw-sm(15px);
      background: #FFFFFF;
      justify-content: left;
      line-height: px2vw-sm(21px);
    }
  }

  &-footer {
    bottom: 0;
    left: 0;
    //position: fixed;
    right: 0;
    z-index: 100;
  }

  &-close,
  &-results {
    align-items: center;
    background-color: #141414; //var(--granite);
    border-radius: 5;
    box-shadow: 0 px2vw-xl(1px) px2vw-xl(3px) 0 rgba(0, 0, 0, 0.16);
    color: var(--white);
    cursor: pointer;
    display: flex;
    height: px2vw-xl(40px);
    justify-content: center;
    line-height: inherit;
    width: 100%;

    @include lg {
      box-shadow: 0 px2vw-lg(1px) px2vw-lg(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-lg(21px);
      height: px2vw-lg(40px);
    }

    @include md {
      box-shadow: 0 px2vw-md(1px) px2vw-md(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-md(16px);
      height: px2vw-md(40px);
      width: px2vw-md(340px);
      margin-left: px2vw-md(15px) !important;
      background-color: #141414;
    }

    @include sm {
      box-shadow: 0 px2vw-sm(1px) px2vw-sm(3px) 0 rgba(0, 0, 0, 0.16);
      font-size: px2vw-sm(16px);
      height: px2vw-sm(40px);
      width: px2vw-sm(346px);
      margin-left: px2vw-sm(13px) !important;
      background-color: #141414;
    }

    i {
      bottom: px2vw-xl(8px);
      left: px2vw-xl(12px);
      position: fixed;

      @include lg {
        bottom: px2vw-lg(8px);
        font-size: px2vw-lg(21px);
        left: px2vw-lg(12px);
      }

      @include md {
        bottom: px2vw-md(8px);
        font-size: px2vw-md(21px);
        left: px2vw-md(12px);
      }

      @include sm {
        bottom: px2vw-sm(8px);
        font-size: px2vw-sm(21px);
        left: px2vw-sm(12px);
      }
    }
  }

  &-results1 {
    background-color: #004fbc; //var(--ocean);

    @include md {
      background-color: #141414;
    }

    @include sm {
      background-color: #141414;
    }
  }
}

.PopupOLD {
  background: var(--alabaster2);

  &-title {
    font-size: px2vw-xl(25px);
    font-weight: 500;
    padding: px2vw-xl(35px) px2vw-xl(25px) px2vw-xl(24px);
    text-align: center;
    font-family: 'Volvo Novum Medium';
    color: #141414 !important;

    @include lg {
      font-size: px2vw-lg(25px);
      padding: px2vw-lg(35px) px2vw-lg(25px) px2vw-lg(24px);
    }

    @include md {
      font-size: px2vw-md(25px);
      padding: px2vw-md(35px) px2vw-md(25px) px2vw-md(24px);
    }

    @include sm {
      font-size: px2vw-sm(25px);
      padding: px2vw-sm(35px) px2vw-sm(25px) px2vw-sm(24px);
    }
  }

  &-subtitle {
    font-size: px2vw-xl(25px);
    margin-bottom: px2vw-xl(24px);
    padding: 0 px2vw-xl(15px);
    text-align: center;
    font-family: 'Volvo Novum Regular';
    color: #141414 !important;

    @include lg {
      font-size: px2vw-lg(25px);
      margin-bottom: px2vw-lg(24px);
      padding: 0 px2vw-lg(15px);
    }

    @include md {
      font-size: px2vw-md(25px);
      margin-bottom: px2vw-md(24px);
      padding: 0 px2vw-md(15px);
    }

    @include sm {
      font-size: px2vw-sm(25px);
      margin-bottom: px2vw-sm(24px);
      padding: 0 px2vw-sm(15px);
    }
  }

  &-phone {
    font-size: px2vw-xl(20px);
    font-weight: 700;
    padding-bottom: px2vw-xl(40px);
    text-align: center;

    @include lg {
      font-size: px2vw-lg(20px);
      padding-bottom: px2vw-lg(40px);
    }

    @include md {
      font-size: px2vw-md(20px);
      padding-bottom: px2vw-md(40px);
    }

    @include sm {
      font-size: px2vw-sm(20px);
      padding-bottom: px2vw-sm(40px);
    }

    i {
      color: var(--ocean);
      font-size: px2vw-xl(30px);
      margin: 0 px2vw-xl(10px) 0 0;
      vertical-align: bottom;

      @include lg {
        font-size: px2vw-lg(30px);
        margin: 0 px2vw-lg(10px) 0 0;
      }

      @include md {
        font-size: px2vw-md(30px);
        margin: 0 px2vw-md(10px) 0 0;
      }

      @include sm {
        font-size: px2vw-sm(30px);
        margin: 0 px2vw-sm(10px) 0 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  &-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 34px;
    max-width: 37.6032210835vw;
    width: 100%;

    @include lg {
      margin-bottom: px2vw-lg(30px);
      padding: px2vw-lg(15px) px2vw-lg(30px);
    }

    @include md {
      margin-bottom: px2vw-md(30px);
      padding: px2vw-md(15px) px2vw-md(30px);
    }

    @include sm {
      flex-flow: wrap;
      margin-bottom: px2vw-sm(30px);
      padding: px2vw-sm(15px) px2vw-sm(30px);
    }
  }

  &-infoBtnold {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 34px;
    max-width: 37.6032210835vw;
    width: 100%;

    @include lg {
      margin-bottom: px2vw-lg(30px);
      padding: px2vw-lg(15px) px2vw-lg(80px);
    }

    @include md {
      margin-bottom: px2vw-md(30px);
      padding: px2vw-md(15px) px2vw-md(80px);
    }

    @include sm {
      flex-flow: wrap;
      margin-bottom: px2vw-sm(30px);
      padding: px2vw-sm(15px) px2vw-sm(80px);
    }
  }

  &-infoBtn {
    // box-sizing: border-box;
    // height: 41px;
    // width: 101px;
    // border: 1px solid #1251B5;
    // border-radius: 1px;
    // background-color: #FFFFFF;
    margin-left: 16px;
  }

  &-infoBtnGroup {
    text-align: left;
    background-color: #f8f8f8;

    @include lg {
      text-align: left;
    }

    @include md {
      text-align: left;
    }

    @include sm {
      text-align: left;
    }
  }

  &-infoBtnCollection {
    margin: 0 auto;
    max-width: px2vw-xl(500px);
    width: 100%;

    @include lg {
      max-width: px2vw-lg(500px);
    }

    @include md {
      max-width: px2vw-md(500px);
    }

    @include sm {
      max-width: px2vw-sm(500px);
      padding: 0 px2vw-sm(20px);
    }
  }

  &-infoImage {
    padding-right: px2vw-xl(33px);

    @include lg {
      padding-right: px2vw-lg(33px);
    }

    @include md {
      padding-right: px2vw-md(33px);
    }

    @include sm {
      padding-right: px2vw-sm(33px);
    }

    img {
      height: px2vw-xl(176px);
      width: px2vw-xl(265px);

      object-fit: cover;

      @include lg {
        height: auto;
        max-width: 246px;
        width: 100%;
      }

      @include md {
        height: px2vw-xl(790px);
        width: px2vw-xl(1200px);
      }

      @include sm {
        height: px2vw-xl(790px);
        width: px2vw-xl(1200px);
      }
    }
  }

  &-infoTitle {
    font-size: px2vw-xl(24px);
    font-weight: 700;

    @include lg {
      font-size: px2vw-lg(24px);
    }

    @include md {
      font-size: px2vw-md(24px);
    }

    @include sm {
      font-size: px2vw-sm(24px);
    }
  }

  &-infoPrice {
    color: var(--ocean);
    display: flex;
    font-size: px2vw-xl(24px);
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: px2vw-xl(5px);

    @include lg {
      font-size: px2vw-lg(24px);
      margin-bottom: px2vw-lg(5px);
    }

    @include md {
      font-size: px2vw-md(24px);
      margin-bottom: px2vw-md(5px);
    }

    @include sm {
      font-size: px2vw-sm(24px);
      margin-bottom: px2vw-sm(5px);
    }
  }

  &-infoPriceLocal {
    color: var(--boulder);
    font-size: px2vw-xl(12px);
    margin-bottom: px2vw-xl(5px);

    @include lg {
      font-size: px2vw-lg(12px);
      margin-bottom: px2vw-lg(5px);
    }

    @include md {
      font-size: px2vw-md(12px);
      margin-bottom: px2vw-md(5px);
    }

    @include sm {
      font-size: px2vw-sm(12px);
      margin-bottom: px2vw-sm(5px);
    }

    b {
      color: var(--granite);
      display: block;
    }
  }

  &-infoText {
    display: flex;
    justify-content: flex-start;
    margin-bottom: px2vw-xl(10px);

    @include lg {
      margin-bottom: px2vw-lg(10px);
    }

    @include md {
      margin-bottom: px2vw-md(10px);
    }

    @include sm {
      margin-bottom: px2vw-sm(10px);
    }

    &:last-child {
      margin: 0;
    }
  }

  &-infoBox {
    flex: 1;
    text-align: left;
  }

  &-form {
    margin: 0 auto;
    max-width: px2vw-xl(500px);
    width: 100%;

    @include lg {
      max-width: px2vw-lg(500px);
    }

    @include md {
      max-width: initial;
      padding: 0;
    }

    @include sm {
      max-width: px2vw-sm(500px);
    }
  }

  &-input {
    margin-top: px2vw-xl(20px);
    text-align: left;

    @include lg {
      margin-top: px2vw-lg(20px);
    }

    @include md {
      margin-top: px2vw-md(20px);
    }

    @include sm {
      margin-top: px2vw-sm(20px);
    }
  }

  &-inputRadio {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  &-inputTitle {
    font-weight: 500;
    margin: 0 0 px2vw-xl(10px);
    text-align: left;
    font-family: 'Volvo Novum Medium';
    color: #141414 !important;

    @include lg {
      margin: 0 0 px2vw-lg(10px);
    }

    @include md {
      margin: 0 0 px2vw-md(10px);
    }

    @include sm {
      margin: 0 0 px2vw-sm(10px);
    }

    span {
      font-weight: 400;
    }
  }

  &-link {
    font-size: px2vw-xl(16px);
    margin: 0 0 0 px2vw-xl(10px);
    font-weight: 500;
    font-family: 'Volvo Novum Medium';
    color: #141414 !important;

    @include lg {
      font-size: px2vw-lg(16px);
      margin: 0 0 0 px2vw-lg(10px);
    }

    @include md {
      font-size: px2vw-md(16px);
    }

    @include sm {
      font-size: px2vw-sm(16px);
    }
  }

  &-newLink {
    font-size: px2vw-xl(14px);
    margin: 0;
    font-weight: 500;
    font-family: 'Volvo Novum Medium';
    color: #141414 !important;

    @include lg {
      font-size: px2vw-lg(14px);
      margin: 0;
    }

    @include md {
      font-size: px2vw-md(14px);
    }

    @include sm {
      font-size: px2vw-sm(14px);
    }
  }

  &-privacy {
    display: flex;
    margin-bottom: px2vw-xl(30px);
    margin-top: px2vw-xl(36px);

    @include lg {
      margin-bottom: px2vw-lg(30px);
      margin-top: px2vw-lg(36px);
    }

    @include md {
      margin-bottom: px2vw-md(30px);
      margin-top: px2vw-md(36px);
    }

    @include sm {
      margin-bottom: px2vw-sm(30px);
      margin-top: px2vw-sm(36px);
    }

    mat-checkbox {
      line-height: 1;
    }
  }

  &-submit {
    padding: 0 0 px2vw-xl(50px);

    @include lg {
      padding: 0 0 px2vw-lg(50px);
    }

    @include md {
      padding: 0 0 px2vw-md(50px);
    }

    @include sm {
      padding: 0 0 px2vw-sm(50px);
    }
  }

  &-close {
    cursor: pointer;
    font-size: px2vw-xl(30px);
    padding: px2vw-xl(36px);
    position: absolute;
    right: 0;
    top: 0;

    @include lg {
      font-size: px2vw-lg(30px);
      padding: px2vw-lg(36px);
    }

    @include md {
      font-size: px2vw-md(30px);
      //padding: px2vw-md(36px);
    }

    @include sm {
      font-size: px2vw-sm(30px);
      //padding: px2vw-sm(36px);
    }
  }

  &-header {
    background: var(--gallery);
    font-weight: 700;
    padding: px2vw-xl(9px) 0;
    text-align: center;
    text-transform: uppercase;

    @include lg {
      padding: px2vw-lg(9px) 0;
    }

    @include md {
      padding: px2vw-md(9px) 0;
    }

    @include sm {
      padding: px2vw-sm(9px) 0;
    }
  }

  &-checkBoxText {
    font-size: px2vw-xl(16px);
    font-family: 'Volvo Novum Medium';
    color: #141414;
    font-weight: 500;

    @include lg {
      font-size: px2vw-lg(16px);
    }

    @include md {
      font-size: px2vw-md(16px);
    }

    @include sm {
      font-size: px2vw-sm(16px);
    }
  }
}

.privacyPolicy {
  text-align: left;
  text-decoration-line: underline;
  @include md {
    margin-left: px2vw-md(0px);
  }

  @include sm {
    margin-left: px2vw-sm(4px);
  }
}

.goToTop {
  color: #fff;
}

.goToTopBgColor {
  background-color: #141414 !important;
}
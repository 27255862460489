.drag-scroll-content {
  //overflow: hidden !important;

  @include md {
      overflow: auto !important;
  }
  @include sm {
    padding-bottom: px2vw-sm(15px);
      overflow: auto !important;
      margin: 0 px2vw-sm(1px);
    width: 100% !important;
  }
}
.drag-scroll-style {
  cursor: pointer;
  ::-webkit-scrollbar {
      width: px2vw-xl(3px);
      height: px2vw-lg(3px);
      @include lg {
        width: px2vw-lg(3px);
        height: px2vw-lg(3px);
      }
  
      @include md {
        -webkit-overflow-scrolling: touch;
        width: px2vw-md(3px);
        height: px2vw-md(3px);
      }
  
      @include sm {
        width: px2vw-sm(3px);
        height: px2vw-sm(3px);
      }
    }
    
    ::-webkit-scrollbar-track {
      border-radius: 7px;
      padding-right: 0%;
      margin-right: 0%;
  
      @include lg {
          margin-top: px2vw-lg(30px);
        }
    
        @include md {
        }
    
        @include sm {
        }
    }
    
    ::-webkit-scrollbar-thumb {
      background: #888B8D;
      border-radius: 10px;
    -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    
    /* Handle on hover */
    
    ::-webkit-scrollbar-thumb:hover {
      background: #E1DFDD;
     
    }
  
    ::-webkit-scrollbar:vertical {
      display: none;
    }
  }
.mat-radio-label-content {
  font-size: px2vw-xl(16px) !important;
  line-height: initial !important;

  @include lg {
    font-size: px2vw-lg(16px) !important;
  }

  @include md {
    font-size: px2vw-md(16px) !important;
  }

  @include sm {
    font-size: px2vw-sm(16px) !important;
  }
}

.mat-radio-container {
  height: px2vw-xl(20px) !important;
  width: px2vw-xl(20px) !important;

  @include lg {
    height: px2vw-lg(20px) !important;
    width: px2vw-lg(20px) !important;
  }

  @include md {
    height: px2vw-md(20px) !important;
    width: px2vw-md(20px) !important;
  }

  @include sm {
    height: px2vw-sm(20px) !important;
    width: px2vw-sm(20px) !important;
  }
}

.mat-radio-outer-circle {
  height: px2vw-xl(20px) !important;
  width: px2vw-xl(20px) !important;
  border-width: px2vw-xl(2px) !important;

  @include lg {
    height: px2vw-lg(20px) !important;
    width: px2vw-lg(20px) !important;
    border-width: px2vw-lg(2px) !important;
  }

  @include md {
    height: px2vw-md(20px) !important;
    width: px2vw-md(20px) !important;
    border-width: px2vw-md(2px) !important;
  }

  @include sm {
    height: px2vw-sm(20px) !important;
    width: px2vw-sm(20px) !important;
    border-width: px2vw-sm(2px) !important;
  }
}

.mat-radio-inner-circle {
  height: px2vw-xl(20px) !important;
  width: px2vw-xl(20px) !important;

  @include lg {
    height: px2vw-lg(20px) !important;
    width: px2vw-lg(20px) !important;
  }

  @include md {
    height: px2vw-md(20px) !important;
    width: px2vw-md(20px) !important;
  }

  @include sm {
    height: px2vw-sm(20px) !important;
    width: px2vw-sm(20px) !important;
  }
}

mat-radio-group.IsList {
  mat-radio-button {
    display: block;
    margin: px2vw-xl(10px) 0;

    @include lg {
      margin: px2vw-lg(10px) 0;
    }

    @include md {
      margin: px2vw-md(10px) 0;
    }

    @include sm {
      margin: px2vw-sm(10px) 0;
    }
  }
}

mat-radio-group.SelectList {
  mat-radio-button {
    display: block;
    border-bottom: solid px2vw-xl(0.5px) var(--alto);

    @include lg {
      border-bottom: solid px2vw-lg(0.5px) var(--alto);
    }

    @include md {
      border-bottom: solid px2vw-md(0.5px) var(--alto);
    }

    @include sm {
      border-bottom: solid px2vw-sm(0.5px) var(--alto);
    }

    i {
      color: #004fbc; //var(--ocean);
      display: none;
      font-size: px2vw-xl(12px);

      @include lg {
        font-size: px2vw-lg(12px);
      }

      @include md {
        font-size: px2vw-md(12px);
      }

      @include sm {
        font-size: px2vw-sm(12px);
      }
    }

    &.mat-radio-checked {
      i {
        display: block;
      }
    }
  }

  .mat-radio-label {
    font-size: px2vw-xl(16px);
    justify-content: space-between;
    padding: px2vw-xl(7px) 0;

    @include lg {
      font-size: px2vw-lg(16px);
      padding: px2vw-lg(7px) 0;
    }

    @include md {
      font-size: px2vw-md(16px);
      padding: px2vw-md(7px) 0;
    }

    @include sm {
      font-size: px2vw-sm(16px);
      padding: px2vw-sm(7px) 0;
    }
  }

  .mat-radio-container {
    display: none;
  }

  .mat-radio-label-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  }
}

.FilterType {
    flex-wrap: wrap;
    border: initial;
    background: transparent;

    .mat-button-toggle + .mat-button-toggle {
        border: initial;
    }

    img {
        display: block;
        margin: 0 auto px2vw-xl(5px);

        @include lg {
            margin: 0 auto px2vw-lg(5px);
        }

        @include md {
            margin: 0 auto px2vw-md(5px);
        }

        @include sm {
            margin: 0 auto px2vw-sm(5px);
        }
    }

    .mat-button-toggle-checked {
        background-color: #E0E0E0;
    }

    button.mat-button-toggle-button {

        .mat-button-toggle-label-content {
            line-height: initial;
            min-width: initial;
            padding: px2vw-xl(10px) px2vw-xl(12px);

            @include lg {
                padding: px2vw-lg(10px) px2vw-lg(12px);
            }

            @include md {
                padding: px2vw-md(10px) px2vw-md(12px);
            }

            @include sm {
                padding: px2vw-sm(10px) px2vw-sm(12px);
            }
        }
    }

    &-img {
        height: px2vw-xl(27px);

        @include lg {
            height: px2vw-lg(27px);
        }

        @include md {
            height: px2vw-md(27px);
        }

        @include sm {
            height: px2vw-sm(27px);
        }
    }

    &.Home {
        border-radius: 0;

        button.mat-button-toggle-button {

            .mat-button-toggle-label-content {
                padding: px2vw-xl(10px) px2vw-xl(41px);

                @include lg {
                    padding: px2vw-lg(10px) px2vw-lg(41px);
                }

                @include md {
                    padding: px2vw-md(10px) px2vw-md(41px);
                }

                @include sm {
                    padding: px2vw-sm(10px) px2vw-sm(41px);
                }
            }
        }

        .mat-button-toggle-appearance-standard {
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

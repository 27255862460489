.H1-VBPD {
    color: var(--granite);
    font-family: "VolvoBroadProDigital", sans-serif;
    font-size: 60px;
    text-transform: uppercase;
}

.H2-VBPD {
    color: var(--granite);
    font-family: "VolvoBroadProDigital", sans-serif;
    font-size: 48px;
    text-transform: uppercase;
}

.H3-VBPD {
    color: var(--granite);
    font-family: "VolvoBroadProDigital", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
}

.H4-Roboto {
    color: var(--granite);
    font-family: "Volvo Novum Medium", "Roboto", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
}

.H5-Roboto {
    color: var(--granite);
    font-family: "Volvo Novum Medium","Roboto", sans-serif;
    font-size: 16px;
}


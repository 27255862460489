.Search {
  &-less {
    font-size: px2vw-xl(16px);
    text-align: center;

    @include lg {
      font-size: px2vw-lg(16px);
    }

    @include md {
      font-size: px2vw-md(16px);
    }

    @include sm {
      font-size: px2vw-sm(16px);
    }
  }

  &-input {
    display: block;
    text-align: left;
    width: 100%;
  }

  &-Selected {
    color: #004fbc; //var(--ocean);
    @include md {
      color: #4D4E53
    }
    @include sm {
      color: #4D4E53
    }
  }
}

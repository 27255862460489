ks-carousel {
    user-select: none;
}


ks-carousel.WithThumbnails {
    #carousel-container {

        > .current-figure {

            > #dots {
                pointer-events: none;
            }

            > #current-image {
                height: px2vw-xl(459px) !important;

                @include lg {
                    height: px2vw-lg(459px) !important;
                }

                @include md {
                    height: px2vw-md(459px) !important;
                }

                @include sm {
                    height: px2vw-sm(459px) !important;
                }
            }

            > .nav-left,
            > .nav-right {
                display: block;
                line-height: px2vw-xl(32px);

                @include lg {
                    line-height: px2vw-lg(32px);
                }

                @include md {
                    line-height: px2vw-md(32px);
                }

                @include sm {
                    line-height: px2vw-sm(32px);
                }
            }
        }


        .left-volvo-image,
        .right-volvo-image {
            background: none;
            font-family: 'Font Awesome 5 Free', sans-serif;
            font-weight: 900;
            font-size: px2vw-xl(32px);
            opacity: 1;
            color: white;
            transition: 100ms;

            @include lg {
                font-size: px2vw-lg(32px);
            }

            @include md {
                font-size: px2vw-md(32px);
            }

            @include sm {
                font-size: px2vw-sm(32px);
            }

            &::after {
                content: "\f054";
            }
        }

        .left-volvo-image {

            &::after {
                content: "\f053";
            }
        }
    }

    .previews-container > .preview-inner-container {
        justify-content: space-between !important;

        > .preview-image {
            width: 19% !important;
        }
    }


    .empty-volvo-preview-image,
    .left-volvo-preview-image,
    .right-volvo-preview-image {
        display: none;
    }
}


ks-carousel.WithArrows {
    .previews-container {

        > .nav-right,
        > .nav-left {
            top: 50% !important;
            margin: 0 !important;
            transform: translateY(-50%) !important;
            color: black !important;
            font-family: 'Font Awesome 5 Free', sans-serif;
            font-weight: 900;
            border: initial;
            cursor: pointer;
            height: 100%;
            position: absolute;
            min-width: auto;
            background: white;
            width: px2vw-xl(30px);
            display: grid;
            justify-items: center;
            align-content: center;
            padding: 0;
            font-size: px2vw-xl(22px);
            opacity: 1;
            background: rgba(255, 255, 255, 0.5);

            @include lg {
                width: px2vw-lg(30px);
                font-size: px2vw-lg(22px);
            }

            @include md {
                width: px2vw-md(30px);
                font-size: px2vw-md(22px);
            }

            @include sm {
                width: px2vw-sm(30px);
                font-size: px2vw-sm(22px);
            }
        }

        > .nav-right {
            right: 0 !important;

            &::after {
                content: "\f0da";
            }
        }

        > .nav-left {
            left: 0 !important;

            &::after {
                content: "\f0d9";
            }
        }
    }
}

$font-path: '/assets/fonts/';

@import './font-awesome';

@font-face {
  font-family: 'VolvoBroadPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoBroadPro/VolvoBroadPro.otf') format('opentype');
}

@font-face {
  font-family: 'VolvoBroadProDigital';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoBroadProDigital/VolvoBroadProDigital.otf') format('opentype');
}

@font-face {
  font-family: 'Volvo Novum Regular';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoNovum/volvotrucks/VolvoNovum-Regular-webfont.woff2') format('opentype');
}

@font-face {
  font-family: 'Volvo Novum Medium';
  font-style: bold;
  font-weight: 400;
  src: url($font-path+'VolvoNovum/volvotrucks/VolvoNovum-Medium-webfont.woff2') format('opentype');
}

@font-face {
  font-family: 'Volvo Novum Light';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoNovum/volvotrucks/VolvoNovum-Light-webfont.woff2') format('opentype');
}

@font-face {
  font-family: 'Volvo Novum Bold';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoNovum/volvotrucks/VolvoNovum3-Bold.woff2') format('opentype');
}

@font-face {
  font-family: 'Volvo Broad Spread';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'VolvoNovum/volvotrucks/VolvoBroadSpread.woff2') format('opentype');
}
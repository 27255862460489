.Table {
    &-box {}

    &-boxImage {
        img {
            width: 100%;
        }
    }

    &-row {
        display: flex;
        font-size: px2vw-xl(18px);
        justify-content: space-between;
        margin: px2vw-xl(9px) 0;
        position: relative;
        color: var(--light-text-and-icon-subtle, #575757);

        @include lg {
            font-size: px2vw-lg(18px);
            margin: px2vw-lg(9px) 0;
        }

        @include md {
            font-size: px2vw-md(18px);
            margin: px2vw-md(9px) 0;
        }

        @include sm {
            font-size: px2vw-sm(18px);
            margin: px2vw-sm(9px) 0;
            text-align: right;
        }

        //&:hover {
        //    background: #f5f5f5;
        //}
    }

    &-rowTitle {
        margin: px2vw-xl(25px) 0 0;
        padding: 0 0 px2vw-xl(4px);

        @include lg {
            margin: px2vw-lg(25px) 0 0;
            padding: 0 0 px2vw-lg(4px);
        }

        @include md {
            margin: px2vw-md(25px) 0 0;
            padding: 0 0 px2vw-md(4px);
        }

        @include sm {
            margin: px2vw-sm(25px) 0 0;
            padding: 0 0 px2vw-sm(4px);
        }

        &::after {
            border-top: solid px2vw-xl(1px) var(--alto);
            bottom: 0;
            content: "";
            height: px2vw-xl(1px);
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;

            @include lg {
                border-top: solid px2vw-lg(1px) var(--alto);
                height: px2vw-lg(1px);
            }

            @include md {
                border-top: solid px2vw-md(1px) var(--alto);
                height: px2vw-md(1px);
            }

            @include sm {
                border-top: solid px2vw-sm(1px) var(--alto);
                height: px2vw-sm(1px);
            }
        }
    }

    &-cell {}

    &.WithImage {
        display: flex;
        justify-content: space-between;

        @include md {
            flex-flow: column;
        }
    }

    &.WithImage & {
        &-box:first-child {
            flex: 3;
            // padding: 0 px2vw-xl(60px) 0 0;

            @include lg {}

            @include md {
                flex: 1;
                padding: 0;
            }
        }

        &-box:last-child {
            flex: 1;
        }
    }
}

.ResTable {
    font-size: px2vw-xl(12px);
    line-height: px2vw-xl(16px);

    @include lg {
        font-size: px2vw-lg(12px);
        line-height: px2vw-lg(16px);
    }

    @include md {
        font-size: px2vw-md(12px);
        line-height: px2vw-md(16px);
    }

    @include sm {
        font-size: px2vw-sm(12px);
        line-height: px2vw-sm(16px);
    }

    &-thead {
        font-weight: 700;
    }

    &-tbody {}

    &-tr {
        &.isTitle {
            background: var(--porcelain);
            font-weight: 700;
        }
    }

    &-th,
    &-td {
        padding: px2vw-xl(8px) px2vw-xl(16px) px2vw-xl(10px);

        @include lg {
            padding: px2vw-lg(8px) px2vw-lg(16px) px2vw-lg(10px);
        }

        @include md {
            padding: px2vw-md(8px) px2vw-md(16px) px2vw-md(10px);
        }

        @include sm {
            padding: px2vw-sm(8px) px2vw-sm(16px) px2vw-sm(10px);
        }

        &:nth-child(1) {
            max-width: px2vw-xl(150px);
            width: px2vw-xl(150px);

            @include lg {
                max-width: px2vw-lg(150px);
                width: px2vw-lg(150px);
            }

            @include md {
                max-width: px2vw-md(150px);
                width: px2vw-md(150px);
            }

            @include sm {
                max-width: px2vw-sm(150px);
                width: px2vw-sm(150px);
            }
        }

        &:nth-child(2) {
            max-width: px2vw-xl(180px);
            width: px2vw-xl(180px);

            @include lg {
                max-width: px2vw-lg(180px);
                width: px2vw-lg(180px);
            }

            @include md {
                max-width: px2vw-md(180px);
                width: px2vw-md(180px);
            }

            @include sm {
                max-width: px2vw-sm(180px);
                width: px2vw-sm(180px);
            }
        }

        &:nth-child(3) {
            max-width: px2vw-xl(320px);
            width: px2vw-xl(320px);

            @include lg {
                max-width: px2vw-lg(320px);
                width: px2vw-lg(320px);
            }

            @include md {
                max-width: px2vw-md(320px);
                width: px2vw-md(320px);
            }

            @include sm {
                max-width: px2vw-sm(320px);
                width: px2vw-sm(320px);
            }
        }

        &:nth-child(4) {
            max-width: px2vw-xl(130px);
            width: px2vw-xl(130px);

            @include lg {
                max-width: px2vw-lg(130px);
                width: px2vw-lg(130px);
            }

            @include md {
                max-width: px2vw-md(130px);
                width: px2vw-md(130px);
            }

            @include sm {
                max-width: px2vw-sm(130px);
                width: px2vw-sm(130px);
            }
        }

        &:nth-child(5) {}
    }
}
.ngx-pagination {
  margin-bottom: 0 !important;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: transparent !important;
  opacity: 0.39;
}

.ngx-pagination li {
  font-size: px2vw-xl(14px);
  font-weight: 700;
  margin: 0 !important;
  opacity: 0.39;

  @include lg {
    font-size: px2vw-lg(14px);
  }

  @include md {
    font-size: px2vw-md(14px);
  }

  @include sm {
    font-size: px2vw-sm(14px);
  }
}

.ngx-pagination li:first-child,
.ngx-pagination li:last-child {
  margin: 0;
  opacity: 1;

  &.disabled {
    opacity: 0.6;
  }

  a {
    color: #141414; //var(--ocean);
  }
}

.ngx-pagination .current {
  background: none !important;
  color: #141414 !important;
  opacity: 1 !important;
}

.ngx-pagination li {
  padding: 0;
}

.ngx-pagination .current,
.ngx-pagination li a {
  cursor: pointer;
  padding: px2vw-xl(3px) px2vw-xl(20px) !important;

  @include lg {
    padding: px2vw-lg(3px) px2vw-lg(20px) !important;
  }

  @include md {
    padding: px2vw-md(3px) px2vw-md(20px) !important;
  }

  @include sm {
    padding: px2vw-sm(3px) px2vw-sm(8px) !important;
  }
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: '\f104' !important;
  font-family: 'Font Awesome 5 Pro', sans-serif;
  margin: 0 px2vw-xl(5px) 0 0 !important;
  vertical-align: initial;

  @include lg {
    margin: 0 px2vw-lg(5px) 0 0 !important;
  }

  @include md {
    margin: 0 px2vw-md(5px) 0 0 !important;
  }

  @include sm {
    margin: 0 px2vw-sm(5px) 0 0 !important;
  }
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: '\f105' !important;
  font-family: 'Font Awesome 5 Pro', sans-serif;
  margin: 0 0 0 px2vw-xl(5px) !important;
  vertical-align: initial;

  @include lg {
    margin: 0 0 0 px2vw-lg(5px) !important;
  }

  @include md {
    margin: 0 0 0 px2vw-md(5px) !important;
  }

  @include sm {
    margin: 0 0 0 px2vw-sm(5px) !important;
  }
}

.ngx-pagination .disabled {
  color: #575757 !important;
}
mat-sidenav {
    margin: 0;

    &-container {
        height: 100%;
    }

    &.mat-drawer {
        position: fixed;
        z-index: 111;
    }
}

mat-sidenav-container {
    position: initial;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
}

.mat-drawer-content {
    position: initial !important;
    z-index: initial !important;
    display: initial !important;
    height: initial !important;
    overflow: initial !important;
}

.mat-drawer-container {
    position: initial !important;
    z-index: initial !important;
    box-sizing: initial !important;
    -webkit-overflow-scrolling: initial !important;
    display: initial !important;
    overflow: initial !important;
}

.mat-drawer-container {
    background-color: var(--alabaster);
    height: 100vh;
}

.mat-drawer-backdrop.mat-drawer-shown {
    position: fixed;
    z-index: 111;
}

.isProduct {
    width: 75.3%;
}


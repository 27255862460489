.Skeleton {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: skeletonGradient;
    animation-timing-function: linear;
    background: linear-gradient(to right, var(--gallery) 40%, #ddd 50%, var(--gallery) 60%);
    background-color: transparent;
    background-size: 200% auto;
    position: relative;
}

.IsSkeleton {
    pointer-events: none;
}

@keyframes skeletonGradient {

    0% {
        background-position: 150% center;
    }

    100% {
        background-position: -50% center;
    }
}

.volvo-popup {
  width: px2vw-xl(700px) !important;
  max-height: 80vh;

  a {
    color: var(--granite);
  }

  @include lg {
    width: px2vw-lg(700px) !important;
  }

  @include md {
    width: px2vw-md(700px) !important;
  }

  @include md {
    height: 100%;
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
  }

  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
  }
}

.volvo-popup-sold {
  width: px2vw-xl(700px) !important;
  max-height: 80vh;
  
  a {
    color: var(--granite);
  }

  @include lg {
    width: px2vw-lg(700px) !important;
  }

  @include md {
    width: px2vw-md(700px) !important;
  }

  @include md {
   // height: 33%;
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
  }

  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
    border-radius: px2vw-xl(20px);

    @include lg{
      border-radius: px2vw-lg(20px);
    }
    @include md{
      margin: px2vw-md(32px) !important;
      border-radius: px2vw-md(20px);
    }
    @include sm {
      background: var(--bg, #FFF);

      box-shadow: 0px 0.6px 3px 0px rgba(0, 0, 0, 0.14), 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
      margin: px2vw-sm(24px) !important;
      border-radius: px2vw-sm(20px);
      max-height: px2vw-sm(500px);
      height: auto;
    }
  }
}

.volvo-popup-dealership {
  width: px2vw-xl(1106px) !important;
  height: auto !important;

  @include lg {
    width: px2vw-lg(1106px) !important;
    height: auto !important;
  }

  @include md {
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
    height: 100% !important;
    background-color: #F7F7F7;
  }

  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
    background: #F7F7F7;
  }
}

.volvo-popup-notification {
  .mat-dialog-container {
    overflow: visible;
    padding: 0px 100px 35px 100px;
  }
}

.volvo-popup-fs {
  height: 100%;
  width: 100% !important;
  max-width: 100% !important;
  position: relative !important;

  .mat-dialog-container {
    padding: 0;
  }
}

.volvo-popup-fs-transparent {
  @extend .volvo-popup-fs;

  .mat-dialog-container {
    background: rgba(0, 0, 0, 0.75);
  }
}

.DealershipPopup {
  .mat-dialog-container {
    height: auto;
    padding: 0;
  }
}

.volvo-popup-region {
    @include sm {
        max-width: -webkit-fill-available !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
    }

    .mat-dialog-container {
        
        padding: 0;
        width: px2vw-xl(400px) !important;
        max-height: px2vw-xl(1000px);
        height: px2vw-xl(400px);

        @include xl {
            width: px2vw-xl(400px) !important;
            max-height: px2vw-xl(1000px);
            height: px2vw-xl(400px);
            overflow-y: hidden;
        }

        @include lg {
            width: px2vw-lg(400px) !important;
            max-height: px2vw-lg(1000px);
            height: px2vw-lg(400px);
            overflow-y: hidden;
        }

        @include md {
            width: px2vw-md(400px) !important;
            max-height: px2vw-md(1000px);
            height: px2vw-md(500px);
            overflow-y: hidden;
        }

        @include sm {
            width: px2vw-sm(400px) !important;
            max-height: px2vw-sm(1000px);
            height: px2vw-sm(820px);
            //height:100%;
            //margin-right:0 !important;
            //margin-top: px2vw-sm(65px) !important;
            overflow-y: hidden;
        }
    }
}

.volvo-no-filter-popup {
  @include sm {
    max-height: initial;
    max-width: 93vw !important;
    width: 100% !important;
  }
  .mat-dialog-container {
    padding: 0;
    width: px2vw-xl(515px) !important;
    max-height: px2vw-xl(1000px);
    height: px2vw-xl(182px);

    @include xl {
      width: px2vw-xl(515px) !important;
      max-height: px2vw-xl(1000px);
      height: px2vw-xl(182px);
    }

    @include lg {
      width: px2vw-lg(515px) !important;
      max-height: px2vw-lg(1000px);
      height: px2vw-lg(182px);
    }

    @include md {
      width: px2vw-md(515px) !important;
      max-height: px2vw-md(1000px);
      height: px2vw-md(250px);
    }

    @include sm {
      width: px2vw-sm(370px) !important;
      max-height: px2vw-sm(1000px);
      height: px2vw-sm(182px);
    }
  }
}

.volvo-notification-popup {
  @include md {
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
    height: 100% !important;
    //background-color: #F7F7F7;
  }

  .mat-dialog-container {
    padding: 0;
    width: px2vw-xl(515px) !important;
    max-height: px2vw-xl(1000px);
    min-height: px2vw-xl(250px);
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    @include xl {
      width: px2vw-xl(515px) !important;
      max-height: px2vw-xl(1000px);
      min-height: px2vw-xl(250px);
    }

    @include lg {
      width: px2vw-lg(515px) !important;
      max-height: px2vw-lg(1000px);
      min-height: px2vw-lg(250px);
    }

    @include md {
      max-height: px2vw-md(1000px);
      width: 100% !important;
      height: 100% !important;
      background-color: #F7F7F7;
    }

    @include sm {
      max-height: px2vw-sm(1000px);
      width: 100% !important;
      height: 100% !important;
      background-color: #F7F7F7;
    }
  }
}

.volvo-notificationSuccess-popup {
  @include md {
    max-height: initial;
    max-width: 106vw !important;
    width: 100% !important;
    height: auto !important;
    //background-color: #F7F7F7;
    border-radius: 10px;
  }

  .mat-dialog-container {
    padding: 0;
    width: px2vw-xl(515px) !important;
    max-height: px2vw-xl(1000px);
    min-height: px2vw-xl(250px);
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    @include xl {
      width: px2vw-xl(515px) !important;
      max-height: px2vw-xl(1000px);
      min-height: px2vw-xl(250px);
    }

    @include lg {
      width: px2vw-lg(515px) !important;
      max-height: px2vw-lg(1000px);
      min-height: px2vw-lg(250px);
    }

    @include md {
      max-height: px2vw-md(1000px);
      width: 100% !important;
      min-height: 0;
      border-radius:20px 20px 0 0;
      //height: 100% !important;
      //background-color: #F7F7F7;
    }

    @include sm {
      max-height: px2vw-sm(1000px);
      width: 100% !important;
      //height: 100% !important;
      //background-color: #F7F7F7;
    }
  }
}
.volvo-popup-email {
  width: px2vw-xl(1106px) !important;
  height: auto !important;

  @include lg {
    width: px2vw-lg(1106px) !important;
    height: auto !important;
  }

  @include md {
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
    height: 100% !important;
    background-color: #F7F7F7;
  }

  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
    background: #F7F7F7;
  }
}

.volvo-popup-vfs {
  width: px2vw-xl(1000px) !important;
  max-height: 85vh;

  a {
    color: var(--granite);
  }

  @include lg {
    width: px2vw-lg(1000px) !important;
  }

  @include md {
    width: px2vw-md(706px) !important;
  }

  @include sm {
    width: px2vw-sm(390px) !important;
  }

  @include md {
    height: 100%;
    max-height: initial;
    max-width: 100vw !important;
    width: 100% !important;
  }

  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
    background: #F7F7F7;
  }
}

.MatInputSearchVfs {
  //height: px2vw-xl(40px) !important;
  width: px2vw-xl(435px) !important;

  @include lg {
   // height: px2vw-lg(40px) !important;
    width: px2vw-lg(430px) !important;
  }

  @include md {
    //height: px2vw-md(39px) !important;
    width: px2vw-md(301px) !important;
  }

  @include sm {
    //height: px2vw-sm(39px) !important;
    width: px2vw-sm(340px) !important;
  }
}

.Vfs {
  margin: 0 px2vw-xl(50px) px2vw-xl(32px) px2vw-xl(80px);

  @include lg {
    margin: 0 0 px2vw-lg(32px) px2vw-lg(70px);
  }

  @include md {
    margin: 0 px2vw-md(20px);
  }

  &-logo {
    padding: px2vw-xl(16px) 0 !important;

    @include lg {
      padding: px2vw-lg(16px) 0 !important;
    }

    @include md {
      display: none;
    }

    img {
      height: px2vw-xl(8.16px);
      width: px2vw-xl(98px);
      left: px2vw-xl(22px);
      top: px2vw-xl(22px);

      @include lg {
        height: px2vw-lg(8.16px);
        width: px2vw-lg(98px);
        left: px2vw-lg(22px);
        top: px2vw-lg(22px);
      }

      @include md {
        display: none;
      }
    }

    &-text {
      font-family: 'Volvo Novum Regular';
      font-weight: 400;
      letter-spacing: -0.3692307770252228px;
      text-align: left;
      padding-top: px2vw-xl(15px);
      font-size: px2vw-xl(14px);
      line-height: px2vw-xl(17px);

      @include lg {
        padding-top: px2vw-lg(15px);
        font-size: px2vw-lg(14px);
        line-height: px2vw-lg(17px);
      }

      @include md {
        display: none;
      }
    }


    &-subText {
      font-family: 'Volvo Novum Regular';
      font-weight: 500;
      letter-spacing: -0.3692307770252228px;
      text-align: left;
      font-size: px2vw-xl(14px);
      line-height: px2vw-xl(17px);

      @include lg {
        font-size: px2vw-lg(14px);
        line-height: px2vw-lg(17px);
      }

      @include md {
        display: none;
      }
    }
  }

  &-close {
    cursor: pointer;
    font-size: px2vw-xl(24px);
    padding: px2vw-xl(30px) px2vw-xl(50px) px2vw-xl(30px) px2vw-xl(85px);
    position: absolute;
    right: 0;
    top: 0;

    @include lg {
      font-size: px2vw-lg(24px);
      padding: px2vw-lg(30px) px2vw-lg(50px) 0 0;
    }

    @include md {
      font-size: px2vw-md(24px);
      padding: px2vw-md(48px) px2vw-md(28px) px2vw-md(28px);
    }

    @include sm {
      font-size: px2vw-sm(24px);
      padding: px2vw-sm(38px) px2vw-sm(16px) px2vw-sm(16px);
    }
  }

  &-header {
    display: flex;
    margin: 0 auto 12px;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.2px;

    &-title {
      font-family: 'Volvo Novum Medium';
      font-weight: 500;
      text-align: left;
      line-height: px2vw-xl(26px);
      font-size: px2vw-xl(20px);

      @include lg {
        line-height: px2vw-lg(26px);
        font-size: px2vw-lg(20px);
      }

      @include md {
        line-height: px2vw-md(26px);
        font-size: px2vw-md(20px);
        padding-top: px2vw-md(30px);
      }

      @include sm {
        line-height: px2vw-sm(26px);
        font-size: px2vw-sm(20px);
        padding-top: px2vw-sm(30px);
      }
    }
  }

  &-EmailUs {
    display: flex;
    //padding-top: px2vw-xl(24px);
    max-width: 37.6032210835vw;
    width: 100%;

    @include lg {
      font-size: px2vw-lg(12px);
    }

    @include md {
      display: none;
    }

    @include sm {
      display: none;
    }

    &-Image {
      margin-right: px2vw-xl(16px);
      height: px2vw-xl(106px);
      width: px2vw-xl(149px);
      left: px2vw-xl(54px);
      top: px2vw-xl(130px);

      @include lg {
        margin-right: px2vw-lg(16px);
        height: px2vw-lg(106px);
        width: px2vw-lg(149px);
        left: px2vw-lg(54px);
        top: px2vw-xl(130px);
      }

      @include md {
        margin: 0;
      }

      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    &-infoVat {
      color: #141414;
      font-size: px2vw-xl(12px);

      @include lg {
        font-size: px2vw-lg(12px);
      }

      @include md {
        font-size: px2vw-md(12px);
      }

      @include sm {
        font-size: px2vw-sm(12px);
        display: block;
        margin-top: px2vw-sm(5px);
      }
    }

    &-Info {
      text-align: left;
      width: 100%;

      @include md {
        text-align: center;
      }
    }
    

    &-LocalPrice {
      font-weight: 400;
      letter-spacing: 0.24px;
      font-size: px2vw-xl(12px);
      line-height: px2vw-xl(16px);
      //padding: 5px 0;

      @include lg {
        font-size: px2vw-lg(12px);
        line-height: px2vw-lg(16px);
      }
      span {
        color: #4D4E53;
      }
    }

    &-Title {
      font-size: px2vw-xl(16px);
      line-height: px2vw-xl(24px);
      font-weight: 500;
      //padding: 5px 0;
      font-family: 'Volvo Novum Medium';
      color: #4D4E53;

      @include lg {
        font-size: px2vw-lg(16px);
        line-height: px2vw-lg(24px);
      }

      @include md {
        font-size: px2vw-md(16px);
      }

      @include sm {
        font-size: px2vw-sm(16px);
        display: block;
        margin-top: px2vw-sm(5px);
      }
    }

    &-SubTitle {
      color: #4D4E53;
      font-size: px2vw-xl(16px);
      line-height: px2vw-xl(24px);
      font-weight: 400;
      font-family: 'Volvo Novum Regular';
      color: #53565A;

      @include lg {
        font-size: px2vw-lg(16px);
        line-height: px2vw-lg(24px);
      }

      @include md {
        font-size: px2vw-md(16px);
      }

      @include sm {
        font-size: px2vw-sm(16px);
        display: block;
        margin-top: px2vw-sm(5px);
      }
    }

    &-Text {
      font-size: px2vw-xl(16px);
      line-height: px2vw-xl(24px);
      //padding: 5px 0;
      font-weight: 400;

      @include lg {
        font-size: px2vw-lg(16px);
        line-height: px2vw-lg(24px);
      }

      span {
        color: #4D4E53;
      }
    }
    &-Axle {
      font-family: "Volvo Novum Regular";
      font-size: px2vw-xl(14px);
      line-height: px2vw-xl(20px);

      @include lg {
          font-size: px2vw-lg(14px);
          line-height: px2vw-lg(20px);
      }

      @include md {
          font-size: px2vw-md(14px);
          line-height: px2vw-md(20px);
      }

      @include sm {
          font-size: px2vw-sm(14px);
          line-height: px2vw-sm(20px);
      }
    }
    &-RegNo {
      color: #4D4E53;
      font-family: "Volvo Novum Regular";
      font-size: px2vw-xl(12px);
      line-height: px2vw-xl(20px);

      @include lg {
          font-size: px2vw-lg(12px);
          line-height: px2vw-lg(20px);
      }

      @include md {
          font-size: px2vw-md(12px);
          line-height: px2vw-md(20px);
      }

      @include sm {
          font-size: px2vw-sm(12px);
          line-height: px2vw-sm(20px);
      }
    }
  }

  &-input {
    //display: flex;
    justify-content: space-between;
    text-align: left;
    position: relative;

    @include md {
      display: block;
    }

    &-title {
      text-align: left;
      font-family: 'Volvo Novum Regular';
      font-weight: 400;
      letter-spacing: 0.15px;
      color: #141414;
      font-size: px2vw-xl(16px);
      line-height: px2vw-xl(24px);
      padding: px2vw-xl(10px) 0 0;

      @include lg {
        font-size: px2vw-lg(16px);
        line-height: px2vw-lg(24px);
        padding: px2vw-lg(10px) 0 0;
      }

      @include md {
        font-size: px2vw-md(16px);
        line-height: px2vw-md(24px);
        padding: px2vw-md(16px) 0 0;
      }

      @include sm {
        font-size: px2vw-sm(16px);
        line-height: px2vw-sm(24px);
        padding: px2vw-sm(12px) 0 0;
      }
    }

    &-checkBoxText {
      color: #141414;
      font-family: 'Volvo Novum Medium';
      font-weight: 400;
      font-size: px2vw-xl(14px);
      line-height: px2vw-xl(20px);
      white-space: break-spaces;
      letter-spacing: 0.28px;

      @include lg {
        font-size: px2vw-lg(14px);
        line-height: px2vw-lg(20px);
      }

      @include md {
        font-size: px2vw-md(14px);
        line-height: px2vw-md(20px);
        white-space: pre-line;
      }

      @include sm {
        font-size: px2vw-sm(14px);
        line-height: px2vw-sm(20px);
        white-space: pre-line;
        margin: px2vw-sm(18px) px2vw-sm(18px) px2vw-sm(18px) px2vw-sm(5px);
      }
    }

    &-display {
      display: flex;
    }

  }

  &-Input1 {
    //background: #ffffff;
    //border: 0.5px solid #a7a8a9;
    width: px2vw-xl(430px);
    height: px2vw-xl(40px);
    margin: px2vw-xl(10px) 0;
    //border-radius: 5px;

    @include lg {
      width: px2vw-lg(430px);
      height: px2vw-lg(40px);
      margin: px2vw-lg(10px) 0;
    }

    @include md {
      width: 100%;
      height: px2vw-md(40px);
      margin: px2vw-md(10px) 0;
      padding-top: px2vw-md(0px);
    }

    @include sm {
      width: px2vw-sm(340px);
      height: px2vw-sm(35px);
      margin: px2vw-sm(10px) 0;
      padding-top: px2vw-sm(0px);
    }

    &-display {
      display: flex;
    }

    &-padding24 {
      padding: px2vw-xl(20px) 0 0;

      @include lg {
        padding: px2vw-lg(20px) 0 0;
      }

      @include md {
        padding: px2vw-md(20px) 0 0;
      }

      @include sm {
        padding: px2vw-sm(20px) 0 0;
      }
    }

    input {
      border: none;
      color: #141414;
      font-family: 'Volvo Novum Regular';
      font-weight: 400;
      padding-left: px2vw-xl(10px);
      font-size: px2vw-xl(16px);
      width: px2vw-xl(360px);
      line-height: px2vw-xl(14px);
      height: px2vw-xl(35px);
      //border-radius: 5px;

      @include lg {
        font-size: px2vw-lg(16px);
        width: px2vw-lg(360px);
        line-height: px2vw-lg(14px);
        padding-left: px2vw-lg(10px);
        height: px2vw-lg(38px);
      }

      @include md {
        font-size: px2vw-md(16px);
        width: px2vw-md(620px);
        line-height: px2vw-md(14px);
        padding-left: px2vw-md(10px);
        height: px2vw-md(39px);
      }

      @include sm {
        font-size: px2vw-sm(16px);
        width: px2vw-sm(310px);
        line-height: px2vw-sm(14px);
        padding-left: px2vw-sm(10px);
        height: px2vw-sm(38px);
      }
    }

    input:focus {
      outline: none;
    }

    img {
      vertical-align: middle;
      padding: px2vw-xl(10px);

      // width: px2vw-xl(18px);
      // height: px2vw-xl(18px);
      @include lg {
        padding: px2vw-lg(10px);
        //width: px2vw-lg(18px);
        //height: px2vw-lg(18px);
      }

      @include md {
        padding: px2vw-md(10px);
        // width: px2vw-md(18px);
        // height: px2vw-md(18px);
      }

      @include sm {
        padding: px2vw-sm(10px);
        //width: px2vw-sm(18px);
        // height: px2vw-sm(18px);
      }
    }

    &-countryDD {
      text-align: left;
      font-family: 'Volvo Novum Regular';
      margin: px2vw-xl(7px) 0;
      font-size: px2vw-xl(16px);
      width: px2vw-xl(430px);
      padding: px2vw-xl(6px) px2vw-xl(10px);
      color: #575757;
      border-radius: 4px;
      border: 1px solid #A9A9A9;

      background: #FFF;

      @include lg {
        margin: px2vw-lg(7px) 0;
        font-size: px2vw-lg(16px);
        width: px2vw-lg(430px);
        padding: px2vw-lg(6px) px2vw-lg(10px);
      }

      @include md {
        margin: px2vw-md(0px);
        font-size: px2vw-md(16px);
        width: px2vw-md(300px);
        //padding: 0 px2vw-md(10px);
        height: px2vw-md(40px);
        padding-top: px2vw-md(5px);
      }

      @include sm {
        margin: px2vw-sm(0px);
        font-size: px2vw-sm(16px);
        width: px2vw-sm(340px);
        //padding: 0 px2vw-sm(10px);
        height: px2vw-sm(35px);
        padding: px2vw-sm(5px);
      }
    }
  }

  &-financing {
    text-align: left;
    padding-top: px2vw-xl(10px);

    @include lg {
      padding-top: px2vw-lg(15px);
    }

    @include md {
      padding-top: px2vw-md(15px);
    }

    @include sm {
      padding-top: 0;
      margin-bottom: px2vw-sm(15px);;
    }
  }

  &-privacy {
    color: #141414;
    font-family: 'Volvo Novum Regular';
    font-style: normal;
    text-align: left;
    font-weight: 400;
    font-size: px2vw-xl(16px);
    line-height: px2vw-xl(24px);
    letter-spacing: 0.28px;
    white-space: pre-line;
    width: px2vw-xl(440px);
    //height: px2vw-xl(72px);
    // margin-top: px2vw-xl(15px);

    @include lg {
      font-size: px2vw-lg(16px);
      line-height: px2vw-lg(24px);
      width: px2vw-lg(430px);
      //height: px2vw-lg(72px);
      // margin-top: px2vw-lg(15px);
    }

    @include md {
      font-size: px2vw-md(16px);
      line-height: px2vw-md(24px);
      //margin-top: px2vw-md(10px);
      //width: px2vw-md(666px);
      //height: px2vw-md(72px);
      width: px2vw-md(680px);
    }

    @include sm {
      font-size: px2vw-sm(16px);
      line-height: px2vw-sm(24px);
      margin-top: px2vw-sm(18px);
      width: auto;
      height: auto; 
    }
  }

  &-submit {
    float: right;
    //margin-right: px2vw-xl(35px);
    //display: flex;
    padding-top: px2vw-xl(25px) ;

    @include lg {
      //float: right;
      //margin-right: px2vw-xl(104px);
      padding-top: px2vw-lg(25px) ;
    }

    @include md {
     // display: unset;
      //float: right;
      //margin-right: px2vw-xl(64px);
      //flex-direction: column-reverse;
      padding-top: px2vw-md(25px);
    }

    @include sm {
      //float: right;
      margin-right: px2vw-xl(64px);
      text-align: right;
      //flex-direction: column-reverse;
      padding-top: px2vw-sm(18px);
      display: unset;
    }

    button {
      color: #A7A8A9;
      background: #E1DFDD;
      border-radius: 4px;
      border: none;
      font-family: 'Volvo Novum Regular';
      font-weight: 400;
      font-size: px2vw-xl(14px);
      //width: px2vw-xl(130px);
      height: px2vw-xl(40px);
      cursor: pointer;
      width: auto;
      float: right;
      padding: 0 px2vw-xl(20px);
      @include lg {
        font-size: px2vw-lg(14px);
        //width: px2vw-lg(130px);
        height: px2vw-lg(40px);
        padding: 0 px2vw-lg(20px);
      }

      @include md {
        
        font-size: px2vw-md(14px);
        //width: auto; //px2vw-md(130px);
        height: px2vw-md(40px);
        margin-top: px2vw-md(15px);
        padding: 0 px2vw-md(20px);
      }

      @include sm {
        font-size: px2vw-sm(14px);
        //width: px2vw-sm(130px);
        height: px2vw-sm(40px);
        margin: px2vw-sm(0px) 0 px2vw-sm(16px);
        padding: 0 px2vw-sm(20px);
      }

      &:hover {
        background: #E1DFDD;
      }
    }

    &-btnColor {
      color: white !important;
      background: #141414 !important;
    }
  }
}

.regularFont{
  font-size: px2vw-xl(16px);
  line-height: px2vw-xl(24px);
  font-family: "Volvo Novum Regular" !important;
  margin: 0;
  font-weight: 400;
  color: #141414 !important;

  @include lg {
    font-size: px2vw-lg(16px);
    line-height: px2vw-lg(24px);
    margin: 0;
  }

  @include md {
    font-size: px2vw-md(16px);
    line-height: px2vw-md(20px);
  }

  @include sm {
    font-size: px2vw-sm(16px);
    line-height: px2vw-sm(20px);
  }
}

.checkboxPadding {
  padding-top: px2vw-xl(16px);

  @include lg {
    padding-top: px2vw-lg(20px);
  }
  @include md {
    text-align: left;
    padding-top: px2vw-md(20px);
  }
}
.volvo-share-popup {
  width: px2vw-xl(700px) !important;
  max-height: 80vh;

  a {
    color: var(--granite);
  }

  @include lg {
    width: px2vw-lg(700px) !important;
  }

  @include md {
    width: px2vw-md(700px) !important;
  }

  @include md {
    //height:  px2vw-md(290px) !important;
    max-height: initial;
    max-width: 100vw !important;
    width: px2vw-sm(342px) !important;
  }
  @include sm {
    // border-radius: var(--radius-extra-large, 20px);
    // background: var(--bg, #FFF);
    // box-shadow: 0px 0.6px 3px 0px rgba(0, 0, 0, 0.14), 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
    width: px2vw-sm(342px) !important;
    height: auto !important;
  }
  .mat-dialog-container {
    overflow-y: auto;
    height: auto;
    padding: 0;
    //@include sm {
      border-radius: var(--radius-extra-large, 20px);
      background: var(--bg, #FFF);
      box-shadow: 0px 0.6px 3px 0px rgba(0, 0, 0, 0.14), 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
    //}
    // @include sm {
    //   height: auto !important;
    // }
  }
}
.mat-tooltip {
    font-size: px2vw-xl(10px);

    @include lg {
        font-size: px2vw-lg(13px);
    }

    @include md {
        font-size: px2vw-md(13px);
    }

    @include sm {
        font-size: px2vw-sm(13px);
    }
}
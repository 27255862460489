.mat-checkbox-inner-container {
    height: px2vw-xl(16px) !important;
    margin-right: px2vw-xl(8px) !important;
    width: px2vw-xl(16px) !important;

    @include lg {
        height: px2vw-lg(16px) !important;
        margin-right: px2vw-lg(8px) !important;
        width: px2vw-lg(16px) !important;
    }

    @include md {
        height: px2vw-md(16px) !important;
        margin-right: px2vw-md(8px) !important;
        width: px2vw-md(16px) !important;
    }

    @include sm {
        height: px2vw-sm(16px) !important;
        margin-right: px2vw-sm(8px) !important;
        width: px2vw-sm(16px) !important;
    }
}

.mat-checkbox-layout .mat-checkbox-label {
    line-height: px2vw-xl(24px);

    @include lg {
        line-height: px2vw-lg(24px);
    }

    @include md {
        line-height: px2vw-md(24px);
    }

    @include sm {
        line-height: px2vw-sm(24px);
    }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background-color: #141414 !important;
    @include md {
        background-color: #141414 !important;
        // width: 18px !important;
        // height: 18px !important;
        border: 2px solid #141414 !important;
    }
    @include sm {
        background-color: #141414 !important;
        // width: 18px !important;
        // height: 18px !important;
        border: 2px solid #141414;
    }
}
.mat-pseudo-checkbox{
    @include md {
        width: 18px !important;
        height: 18px !important;
    }
    @include sm {
        width: 18px !important;
        height: 18px !important;
    }
}
.mat-pseudo-checkbox-checked{
    &::after {
        left:2px !important;
    }
}

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$mat-blue: (
    50: #e3f2fd,
    100: #bbdefb,
    200: #90caf9,
    300: #64b5f6,
    400: #42a5f5,
    500: #2196f3,
    600: #1e88e5,
    700: #1976d2,
    800: #1074bc,
    900: #0d47a1,
    A100: #82b1ff,
    A200: #448aff,
    A400: #2979ff,
    A700: #2962ff,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-red: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #f44336,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #e50019,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$volvo-app-primary: mat-palette($mat-blue, 800);
$volvo-app-accent: mat-palette($mat-blue, 800);

// The warn palette is optional (defaults to red).
$volvo-app-warn: mat-palette($mat-red, 900);

// Create the theme object (a Sass map containing all of the palettes).
$volvo-app-theme: mat-light-theme($volvo-app-primary, $volvo-app-accent, $volvo-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($volvo-app-theme);


@import "mat-select";
@import "mat-button";
@import "mat-form-field";
@import "mat-checkbox";
@import "mat-accordion";
@import "mat-tab-group";
@import "mat-sidenav";
@import "mat-dialog";
@import "mat-button-toggle";
@import "mat-option";
@import "mat-chip";
@import "mat-paginator";
@import "mat-stepper";
@import "mat-menu";
@import "mat-snack-bar";
@import "mat-tooltip";
@import "mat-radio-group";
@import "mat-list";
@import "mat-badge";

button:focus {
    outline: 5px auto var(--ocean);
    outline: none;
}
.mat-select-value-text {
    font-family: "Volvo Novum Regular", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-size: px2vw-xl(14px);

    @include lg {
        font-size: px2vw-lg(14px);
    }

    @include md {
        font-size: px2vw-md(14px);
    }

    @include sm {
        font-size: px2vw-sm(14px);
    }
}

.mat-select-panel .mat-option {
    line-height: px2vw-xl(42px) !important;
    height: px2vw-xl(42px) !important;

    @include lg {
        line-height: px2vw-lg(42px) !important;
        height: px2vw-lg(42px) !important;
    }

    @include md {
        line-height: px2vw-md(42px) !important;
        height: px2vw-md(42px) !important;
    }

    @include sm {
        line-height: px2vw-sm(42px) !important;
        height: px2vw-sm(42px) !important;
    }
}

.mat-select-arrow {
    border-left: px2vw-xl(5px) solid transparent !important;
    border-right: px2vw-xl(5px) solid transparent !important;
    border-top: px2vw-xl(5px) solid !important;
    margin: 0 px2vw-xl(4px) !important;

    @include lg {
        border-left: px2vw-lg(5px) solid transparent !important;
        border-right: px2vw-lg(5px) solid transparent !important;
        border-top: px2vw-lg(5px) solid !important;
        margin: 0 px2vw-lg(4px) !important;
    }

    @include md {
        border-left: px2vw-md(5px) solid transparent !important;
        border-right: px2vw-md(5px) solid transparent !important;
        border-top: px2vw-md(5px) solid !important;
        margin: 0 px2vw-md(4px) !important;
    }

    @include sm {
        border-left: px2vw-sm(5px) solid transparent !important;
        border-right: px2vw-sm(5px) solid transparent !important;
        border-top: px2vw-sm(5px) solid !important;
        margin: 0 px2vw-sm(4px) !important;
    }
}

//.SortMenu {
//    .mat-menu-content:not(:empty) {
//        padding: 0;
//    }
//
//    .mat-button {
//        display: block;
//        text-align: left;
//        font-weight: 400;
//        font-size: px2vw-xl(14px);
//        padding: px2vw-xl(5px) px2vw-xl(20px);
//        width: 100%;
//
//        @include lg {
//            font-size: px2vw-lg(14px);
//            padding: px2vw-lg(5px) px2vw-lg(20px);
//        }
//
//        @include md {
//            font-size: px2vw-md(14px);
//            padding: px2vw-md(5px) px2vw-md(20px);
//        }
//
//        @include sm {
//            font-size: px2vw-sm(14px);
//            padding: px2vw-sm(5px) px2vw-sm(20px);
//        }
//
//        &.active {
//            font-weight: 700;
//        }
//    }
//}



.AutoMenu.mat-menu-panel {
    min-width: initial;
    max-width: initial;
    overflow: initial;
    max-height: initial;
    border-radius: initial;
    outline: 0;
    min-height: initial;

    .mat-menu-content {
        padding: 0;
    }
}



button%MatBtn,
button.MatBtn {
    background: var(--geyser);
    font-size: px2vw-xl(16px);
    min-width: px2vw-xl(64px);
    line-height: px2vw-xl(36px);
    padding: 0 px2vw-xl(16px);
    border-radius: 0;

    @include lg {
        font-size: px2vw-lg(16px);
        min-width: px2vw-lg(64px);
        line-height: px2vw-lg(36px);
        padding: 0 px2vw-lg(16px);
    }

    @include md {
        font-size: px2vw-md(16px);
        min-width: px2vw-md(64px);
        line-height: px2vw-md(36px);
        padding: 0 px2vw-md(16px);
    }

    @include sm {
        font-size: px2vw-sm(16px);
        min-width: px2vw-sm(64px);
        line-height: px2vw-sm(36px);
        padding: 0 px2vw-sm(16px);
    }

    &:hover {
        background: #c2cad0;
    }

    &.mat-primary {
        color: var(--white);
        background: #141414;

        &:hover {
            background: #141414;
        }

        &[disabled] {
            color: rgba(255, 255, 255, .2);

            &:hover {
                background: var(--ocean);
            }
        }

        i {
            color: var(--white) !important;
        }
    }
}

.BtnIcon {
    min-width: auto !important;
    padding: px2vw-xl(5px) !important;

    @include lg {
        padding: px2vw-lg(5px) !important;
    }

    @include md {
        padding: px2vw-sm(16px) px2vw-sm(5px) px2vw-sm(5px) !important;
    }

    @include sm {
        padding: px2vw-sm(16px) px2vw-sm(5px) px2vw-sm(5px) !important;
    }

    i {
        line-height: initial;
    }
}

button.BtnAuto {
    @extend %MatBtn;

    min-width: auto;
    line-height: unset;

    .mat-button-wrapper {
        display: inline-block;
        vertical-align: middle;
    }
}

button.BigBtn {
    @extend %MatBtn;

    background: var(--dust);
    box-shadow: px2vw-xl(3px) px2vw-xl(3px) px2vw-xl(10px) 0 rgba(0, 0, 0, .28);
    font-size: px2vw-xl(18px);
    min-width: px2vw-xl(130px) !important;
    line-height: unset !important;
    height: px2vw-xl(50px);
    padding: px2vw-xl(5px) !important;

    @include lg {
        box-shadow: px2vw-lg(3px) px2vw-xl(3px) px2vw-xl(10px) 0 rgba(0, 0, 0, .28);
        font-size: px2vw-lg(18px);
        min-width: px2vw-lg(130px) !important;
        height: px2vw-lg(50px);
        padding: px2vw-lg(5px) !important;
    }

    @include md {
        box-shadow: px2vw-md(3px) px2vw-xl(3px) px2vw-xl(10px) 0 rgba(0, 0, 0, .28);
        font-size: px2vw-md(18px);
        min-width: px2vw-md(130px) !important;
        height: px2vw-md(50px);
        padding: px2vw-md(5px) !important;
    }

    @include sm {
        box-shadow: px2vw-sm(3px) px2vw-xl(3px) px2vw-xl(10px) 0 rgba(0, 0, 0, .28);
        font-size: px2vw-sm(18px);
        min-width: px2vw-sm(90px) !important;
        height: px2vw-sm(40px);
        padding: px2vw-sm(5px) !important;
    }

    i {
        margin-right: px2vw-xl(5px);

        @include lg {
            margin-right: px2vw-lg(5px);
        }

        @include md {
            margin-right: px2vw-md(5px);
        }

        @include sm {
            margin-right: px2vw-sm(5px);
        }
    }

    .mat-button-wrapper {
        padding: px2vw-xl(10px);

        @include lg {
            padding: px2vw-lg(10px);
        }

        @include md {
            padding: px2vw-md(10px);
        }

        @include sm {
            padding: px2vw-sm(10px);
        }
    }
}

.buttonCss {
    text-align: center;
    padding: px2vw-xl(28px) 0;

    @include lg {
        padding: px2vw-lg(28px) 0;
    }

    @include md {
        padding: px2vw-md(28px) 0;
    }

    @include sm {
        padding: px2vw-sm(28px) 0;
    }

    button {
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        color: #FFFFFF;
        background: #141414;
        border-radius: 4px;
        border: none;
        font-family: 'Volvo Novum Regular';
        gap: 10px;
        font-weight: 400;
        cursor: pointer;
        //width: px2vw-xl(198px);
        height: px2vw-xl(40px);
        font-size: px2vw-xl(14px);
        line-height: px2vw-xl(14px);
        padding: px2vw-xl(10px) px2vw-xl(20px);

        @include lg {
            //width: px2vw-lg(198px);
            height: px2vw-lg(40px);
            font-size: px2vw-lg(14px);
            line-height: px2vw-lg(14px);
            padding: px2vw-lg(10px) px2vw-lg(20px);
        }

        @include md {
            width: px2vw-md(198px);
            height: px2vw-md(40px);
            font-size: px2vw-md(14px);
            line-height: px2vw-md(14px);
            padding: px2vw-md(10px) px2vw-md(20px);
        }

        @include sm {
            width: px2vw-sm(198px);
            height: px2vw-sm(40px);
            font-size: px2vw-sm(14px);
            line-height: px2vw-sm(14px);
            padding: px2vw-sm(10px) px2vw-sm(20px);
        }

        &:hover {
            background: #141414;
        }
    }

    &-btnDisbaleColor {
        color: #A7A8A9 !important;
        background: #E1DFDD !important;
    }
}
.VolvoMatBadge {

    .mat-badge-content {
        color: var(--white);
        font-size: px2vw-xl(10px);
        width: px2vw-xl(18px);
        height: px2vw-xl(18px);
        line-height: px2vw-xl(18px);
        right: px2vw-xl(-18px) !important;
        top: px2vw-xl(-6px) !important;
    }
}

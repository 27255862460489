.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: initial;
}

.mat-step-label {
  font-size: px2vw-xl(16px);

  @include lg {
    font-size: px2vw-lg(16px);
  }

  @include md {
    font-size: px2vw-md(16px);
  }

  @include sm {
    font-size: px2vw-sm(16px);
  }

  &.mat-step-label-selected {
    font-weight: 500;
  }
}
.mat-horizontal-stepper-header
{
  @include md {
    padding:0 !important;
  }
}
.mat-horizontal-content-container
{
  @include md {
    padding:0 !important;
  }
}

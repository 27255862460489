img[volvoLazyImage] {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, transparent 40%, #ddd 50%, transparent 60%);
    background-color: transparent;
    background-size: 200% auto;
    position: relative;
}

img[volvoLazyImage="true"] {
    animation-name: initial;
    background: initial;
}

@keyframes placeHolderShimmer {

    0% {
        background-position: 150% center;
    }

    100% {
        background-position: -50% center;
    }
}

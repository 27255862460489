// color name can be generated via
// http://chir.ag/projects/name-that-color/#d71921
// extract color to "colors root" if we have at least 2 colors in different files

:root {
    // primary
    --granite: #3a4448;
    --grass: #79b928;
    --black: #1d1d1b;
    --white: #fff;
    --cloudberry: #f07d00;
    --ocean: #1074bc;
    --slate: #9a9c9c;
    --dust: #fcfcfc;
    --midnightSun: #fcc970;
    --sky: #cee9ef;
    --fog: #e3e2dd;

    --alto: #d9d9d9;
    --cta-blue: #1251b5;
    --thunder: white;

    // signal
    --successGreen: #009640;
    --warningYellow: #fd0;
    --errorRed: #e30613;

    // accent
    --heater: #7d2472;
    --raspberry: #e7304d;

    // call to action
    --ctaBlue: #1251b5;
    --ctaGranite: #4d4e53;

    // other
    --porcelain: #f7f8f9;
    --geyser: #dfe3e6;
    --boulder: #787878;

    --boxShadow: rgba(0, 0, 0, 0.16);
}

.mat-form-field {
    font-size: inherit;
}

%MatInputSearch,
.MatInputSearch {
    color: var(--granite);
    background: var(--white);
    border-radius: 4px;
     //border: 1px solid #A9A9A9;
    font-size: px2vw-xl(16px);
    width: 100%;

    @include lg {
        font-size: px2vw-lg(16px);
    }

    @include md {
        font-size: px2vw-md(16px);
    }

    @include sm {
        font-size: px2vw-sm(16px);
    }

    .mat-select-value-text {
        font-size: px2vw-xl(18px);

        @include lg {
            font-size: px2vw-lg(18px);
        }

        @include md {
            font-size: px2vw-md(18px);
        }

        @include sm {
            font-size: px2vw-sm(18px);
        }
    }

    .mat-select-arrow-wrapper {
        transform: translateY(0%) !important;
    }

    .mat-form-field-infix {
        border-top: initial !important;
        padding: px2vw-xl(15px) 0 !important;

        @include lg {
            padding: px2vw-lg(15px) 0 !important;
        }

        @include md {
            padding: px2vw-md(15px) 0 !important;
        }

        @include sm {
            padding: px2vw-sm(15px) 0 !important;
        }
    }

    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
        //border-radius: 0 !important;
    }

    .mat-form-field-label {
        top: 1.4375em !important;
    }

    .mat-input-element {
        margin-top: 0 !important;
    }

    .mat-form-field-wrapper {
        margin: 0 !important;
        padding-bottom: 0 !important;
    }

    .mat-form-field-flex {
        margin-top: 0 !important;
    }

    .mat-form-field-outline {
        top: 0 !important;
    }



    &.isDate {

        .mat-input-element {
            text-align: center;
        }
    }
}

.MatInput {
    @extend %MatInputSearch;

    font-size: px2vw-xl(16px);

    @include lg {
        font-size: px2vw-lg(16px);
    }

    @include md {
        font-size: px2vw-md(16px);
    }

    @include sm {
        font-size: px2vw-sm(16px);
    }

    .mat-form-field-infix {
        padding: px2vw-xl(10px) 0 !important;

        @include lg {
            padding: px2vw-lg(10px) 0 !important;
        }

        @include md {
            padding: px2vw-md(10px) 0 !important;
        }

        @include sm {
            padding: px2vw-sm(10px) 0 !important;
        }
    }

    .mat-select-arrow-wrapper {
        padding: 0 px2vw-xl(5px) 0 0;
        transform: translateY(-50%) !important;

        @include lg {
            padding: 0 px2vw-lg(5px) 0 0;
        }

        @include md {
            padding: 0 px2vw-md(5px) 0 0;
        }

        @include sm {
            padding: 0 px2vw-sm(5px) 0 0;
        }
    }

    .mat-select-arrow {
        border: none !important;

        &::after {
            content: '\f107';
            font-family: "Font Awesome 5 Pro", sans-serif;
        }
    }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #004FBC !important;
}

.mat-select:focus .mat-select-trigger .mat-select-arrow {
    color: #004FBC !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #141414 !important;
}
// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl}px) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg}px) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md}px) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm}px) {
        @content;
    }
}

.VolvoAccordion {
  .mat-expansion-panel-header {
    height: auto !important;
    padding: 0 px2vw-xl(2px) px2vw-xl(0px) px2vw-xl(0px);

    @include lg {
      padding: 0 px2vw-lg(2px) px2vw-lg(0px) px2vw-lg(0px);
    }

    @include md {
      padding: px2vw-md(10px) px2vw-md(2px) px2vw-md(20px) px2vw-md(0px);
    }

    @include sm {
      padding: px2vw-sm(10px) px2vw-sm(2px) px2vw-sm(10px) px2vw-sm(0px);
      color: #000000;
    }

    .mat-content {
      overflow: initial;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 px2vw-xl(0px) px2vw-xl(0px);

    @include lg {
      padding: 0 px2vw-lg(0px) px2vw-lg(0px);
    }

    @include md {
      padding: 0 px2vw-md(0px) px2vw-md(15px);
    }

    @include sm {
      padding: 0 px2vw-sm(0px) px2vw-sm(15px);
    }
  }

  .mat-expansion-indicator::after {
    border-width: 0 px2vw-xl(2px) px2vw-xl(2px) 0;
    padding: px2vw-xl(3px);

    @include lg {
      border-width: 0 px2vw-lg(2px) px2vw-lg(2px) 0;
      padding: px2vw-lg(3px);
    }

    @include md {
      border-width: 0 px2vw-md(1px) px2vw-md(1px) 0;
      padding: px2vw-md(7px);
      width: px2vw-md(14px);
      color: #000000;
      vertical-align: px2vw-md(10px);
      margin: 0px 0.5em;
    }

    @include sm {
      border-width: 0 px2vw-sm(1px) px2vw-sm(1px) 0;
      padding: px2vw-sm(7px);
      width: px2vw-sm(14px);
      color: #000000;
      vertical-align: px2vw-sm(10px);
      margin: 0px 0.5em;
    }
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  mat-expansion-panel {
    background-color: transparent;
    border-radius: 0 !important;
    border-top: solid px2vw-xl(1px) var(--alto);
    box-shadow: initial;

    @include lg {
      border-top: solid px2vw-lg(1px) var(--alto);
    }

    @include md {
      border-top: solid px2vw-md(1px) var(--alto);
    }

    @include sm {
      //border-top: solid px2vw-sm(1px) var(--alto);
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  mat-panel-title {
    font-size: px2vw-xl(18px);
    font-weight: 500;
    margin: px2vw-xl(12px) 0;

    @media (orientation: landscape) {
      line-height: normal;
    }

    @include lg {
      font-size: px2vw-lg(18px);
      margin: px2vw-lg(12px) 0;
    }

    @include md {
      font-size: px2vw-md(18px);
      //margin: px2vw-md(12px) 0;
    }

    @include sm {
      font-size: px2vw-sm(18px);
      //margin: px2vw-sm(12px) 0;
    }
  }
}

.VolvoAccordionDealership {
  .mat-content {
    width: 100%;
  }

  .mat-expansion-panel-header-title {
    margin: 0;
    display: flex;
    flex-flow: column;
    align-items: start;
  }

  .mat-expansion-panel-header {
    background: none !important;
    flex-direction: column;
    padding: 10px 16px;
    height: auto;
  }

  .mat-expansion-panel {
    box-shadow: none;
    border: solid 0.5px #707070;
    margin: 10px 0;
    border-radius: 0 !important;
  }

  /* Change border color to blue when focused via keyboard navigation (tab) */
  .mat-expansion-panel:focus {
    border-color: #2B8EDE;
    border-width: 2px; /* Adjust the thickness here */
    outline: none; /* Optional: Remove default focus outline if undesired */
    border-style: solid; /* Ensure the border style is solid */
  }

  .mat-expansion-indicator::after {
    color: #141414;
  }
}

.VolvoAccordionFooter {
  .mat-expansion-panel-body {
    @include md {
      margin: 0px px2vw-md(32px);
      padding: 0;
    }

    @include sm {
      margin: 0px px2vw-sm(16px);
      padding: 0;
    }
  }

  .mat-expansion-panel-header {
    height: 48px;
  }
}

.stickyBox-locale {
  max-height: 20vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  width: 100%;
  cursor: pointer;

  @include md {
    max-height: 21vh;
  }

  @include sm {
    max-height: 24vh;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #141414;
    border-radius: 7px;

    @include md {
      background-color: #141414;
    }

    @include sm {
      background-color: #141414;
    }
  }

}
html,
body {
  height: 100%;
}

body {
  color: #141414;
  font-family: 'Volvo Novum Regular';
  font-size: px2vw-xl(16px);
  line-height: normal;

  @include lg {
    font-size: px2vw-lg(16px);
  }

  @include md {
    font-size: px2vw-md(16px);
  }

  @include sm {
    font-size: px2vw-sm(16px);
  }
}

* {
  box-sizing: border-box;
}

a {
  &.underline {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
  }
}

router-outlet~* {
  height: auto;
  position: absolute;
  width: 100%;
}

// TODO: find better way to handle this - disable scroll, content jumps
body.VolvoPopupIsOpened {
  overflow: hidden;

  .Header,
  router-outlet~* {
    padding-right: px2vw-xl(17px);

    @include lg {
      padding-right: px2vw-lg(17px);
    }

    @include md {
      padding-right: 0;
    }
  }
}

pre {
  font-family: inherit;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.CPrimary {
  color: #141414;
}

.volvofont {
  font-family: 'Volvo Novum Regular';
}

.volvofontmedium {
  font-family: 'Volvo Novum Medium';
}

.volvofontweight {
  font-weight: 500;
}

.VolvoLogo {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  padding: 24px 24px 0;

  img {
    margin-right: px2vw-xl(24px);
    vertical-align: middle;
    width: px2vw-xl(150px);
    color: black;

    @include lg {
      margin-right: px2vw-lg(24px);
      width: px2vw-xl(150px);
    }

    @include md {
      margin-right: px2vw-md(22px);
      width: px2vw-xl(200px);
    }

    @include sm {
      margin-right: px2vw-sm(22px);
      width: px2vw-xl(350px);
    }
  }
}

.volvoFontGrey3 {
  color: #575757;
}
.volvoFactSheet{
  padding: px2vw-xl(20px) 0 0 px2vw-xl(110px);
  @include lg {
    padding: px2vw-lg(20px) 0 0 px2vw-lg(29px);
  }
  @include md {
    padding: 0 0 px2vw-xl(84px) 0;
  }
}
.navigation{
  &-buttonFact{
    cursor: pointer;
    background: white;
    border: 1px solid #888b8d;
    width: px2vw-xl(287px);
    height: px2vw-xl(53px);
    margin: px2vw-xl(20px) px2vw-xl(20px) 0 0;
    
    padding-left: px2vw-xl(20px);
    @include lg {
      width: px2vw-lg(287px);
      height: px2vw-lg(53px);
      margin: px2vw-lg(20px) px2vw-lg(20px) 0 0;
      
      padding-left: px2vw-lg(20px);
    }
    @include md {
      width: px2vw-md(220px);
      height: px2vw-md(40px);
      margin-top: px2vw-md(20px);
      margin-left: px2vw-md(10px);
      padding-left: px2vw-md(10px);
    }
    @include sm {
      width: px2vw-sm(230px);
      height: px2vw-sm(40px);
      margin-top: px2vw-sm(20px);
      margin-left: px2vw-sm(10px);
      padding-left: px2vw-sm(10px);
    }
    img {
      width: px2vw-xl(20px);
      height: px2vw-xl(20px);
      margin-top: px2vw-xl(2px);
      float: right;
      @include lg {
        width: px2vw-lg(20px);
        height: px2vw-lg(20px);
        margin-top: px2vw-lg(3px);
      }
      @include md {
        width: px2vw-md(15px);
        height: px2vw-md(15px);
        margin-top: px2vw-md(3px);
      }
      @include sm {
        width: px2vw-sm(15px);
        height: px2vw-sm(15px);
        margin-top: px2vw-sm(2px);
      }
    }
    &:hover {
      border-color: #53565a;
    }
  }
  &-button {
    background: white;
    border: 1px solid #888b8d;
    width: px2vw-xl(280px);
    height: px2vw-xl(53px);
    margin-top: px2vw-xl(20px);
    margin-right: px2vw-xl(20px);
    padding-left: px2vw-xl(20px);
    @include lg {
      width: px2vw-lg(280px);
      height: px2vw-lg(53px);
      margin-top: px2vw-lg(20px);
      margin-right: px2vw-lg(20px);
      padding-left: px2vw-lg(20px);
    }
    @include md {
      width: px2vw-md(230px);
      height: px2vw-md(40px);
      margin-top: px2vw-md(20px);
      padding-left: px2vw-md(10px);
      margin-right: px2vw-md(10px);
    }
    @include sm {
      width: px2vw-sm(230px);
      height: px2vw-sm(40px);
      margin-top: px2vw-sm(20px);
      padding-left: px2vw-sm(10px);
    }
    &-text {
      color: #53565a;
      font-size: px2vw-xl(18px);
      font-family: "Volvo Novum Regular";
      float: left;
      @include lg {
        font-size: px2vw-lg(18px);
      }
      @include md {
        font-size: px2vw-md(14px);
      }
      @include sm {
        font-size: px2vw-sm(14px);
      }
    }
    img {
      width: px2vw-xl(20px);
      height: px2vw-xl(20px);
      margin-top: px2vw-xl(3px);
      float: right;
      @include lg {
        width: px2vw-lg(20px);
        height: px2vw-lg(20px);
        margin-top: px2vw-lg(2px);
      }
      @include md {
        width: px2vw-md(15px);
        height: px2vw-md(15px);
        margin-top: px2vw-md(3px);
      }
      @include sm {
        width: px2vw-sm(15px);
        height: px2vw-sm(15px);
        margin-top: px2vw-sm(2px);
      }
    }
    &:hover {
      border-color: #53565a;
    }
  }
}
.faqmargin{
    margin-right: px2vw-xl(82px);
    
    @include lg {
        margin-right: px2vw-lg(50px);
    }
    @include md {
        margin-right: px2vw-md(10px);
    }
    @include sm {
        margin-right: px2vw-sm(0px);
    }
}
.tabMobSidePadding {
    @include md {
        padding:0 var(--tablet-side-padding);
    }

    @include sm {
        padding:0 var(--mobile-side-padding);
    }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.tabMobHeroMargin {
    @include md {
        margin: 0 var(--tablet-hero-margin);
    }

    @include sm {
        margin: 0 var(--mobile-hero-margin);
    }
}
.tabMobHeroMarginwithPadding {
    @include md {
        margin:0 var(--tablet-hero-margin);
        padding: 0 var(--tablet-side-padding)!important;
    }

    @include sm {
        margin:0 var(--mobile-hero-margin);
        padding:0 var(--mobile-side-padding)!important;
    }
}
.displayBlock{
    display:block !important;
}

/* Use :focus-visible if supported */
:focus-visible {
    outline: 2px solid #2B8EDE;
    outline-offset: 2px;
}

/* Fallback for older browsers */
:focus:not(:focus-visible) {
    outline: none;
}

button:focus {
  outline: 2px solid #2B8EDE;
  outline-offset: 2px;
}

a:focus {
  outline: 2px dashed #2B8EDE;
}
